import { render, staticRenderFns } from "./Presidente-Getulio.vue?vue&type=template&id=239c2011&scoped=true&"
import script from "./Presidente-Getulio.vue?vue&type=script&lang=js&"
export * from "./Presidente-Getulio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239c2011",
  null
  
)

export default component.exports